import {useEffect, useRef, useState} from "react"
import axios from 'axios'

const _SERVER = 'https://tow-api.dontpushpush.com/plot'

function App() {

    const markers = useRef([])

    const map = useRef()

    useEffect(() => {

        if (map.current === undefined && global.L) {
            const leaflet = global.L
            map.current = leaflet.map('map').setView([3.126814441159645, 101.67799472808838], 15)
            
            map.current.locate({setView: true})

            const apiKey = 'KfRMHUBRrGkTqMTGIICK'

            // MapTiler map
            leaflet.tileLayer(`https://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}.png?key=${apiKey}`, {
                tileSize: 512,
                zoomOffset: -1,
                minZoom: 1,
                attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
                crossOrigin: true
            }).addTo(map.current);

            map.current.on('click', onMapClick)

            getDataAndPlotIt(leaflet)

            setInterval(() => {
                getDataAndPlotIt(leaflet)
            }, 2000)

        }

    }, [global.L])

    function onMapClick(e) {
        console.log("You clicked the map at ", e.latlng);
    }

    function getDataAndPlotIt (leaflet) {
        (async () => {
            try {
                const response = await axios.get(_SERVER)
                response.data.forEach(d => {
                    const ll = d.ll.split(',')
                    let found = false
                    markers.current.forEach(cm => {
                        if (cm.id === d.id) {
                            found = true
                            cm.marker.setLatLng(ll)
                        }
                    })

                    if (found === false) {
                        const icon = leaflet.icon({
                            iconUrl: '/towtruck.png',
                            iconSize: [40,40],
                            iconAnchor: [20,20]
                        })

                        const marker = leaflet.marker(ll, {icon, id: d.id}).bindTooltip(d.id).addTo(map.current)
                        markers.current.push({ll, id: d.id, marker})
                    }

                })
            } catch (err) {
                console.error('fail to get plot', err.toString())
            }
        })()
    }

  return (
    <div className="App">
        <div id={'map'} style={{width: '100vw', height: '100vh'}} />
    </div>
  );
}

export default App;
